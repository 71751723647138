<template>
  <div>
    <div>
      <Header></Header>
    </div>
    <div id="admindiv1">
      <div style="margin-top: 15px;font-family: '苹方',serif;font-size: 13px;margin-left: 10px">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item style="font-size: 13px" :to="{ path: '/manage' }" @click="reall">首页</el-breadcrumb-item>
          <el-breadcrumb-item style="font-size: 13px">工点管理</el-breadcrumb-item>
        </el-breadcrumb>
      </div>

      <div style="margin-top: 8px">
        <el-button type="warning" size="mini" @click="changeFalg()">保存工点顺序</el-button>
      </div>

      <div style="margin-top: 5px;margin-right: 10px">
        <el-button type="primary" size="mini" @click="addOPer()">新增工点</el-button>
      </div>
    </div>
    <!--    展示所有工点信息-->
    <div style="display: flex;justify-content: center;margin-right: 100px;">
      <div style="width: 300px;text-align: center;">
        工区
      </div>
      <div style="width: 350px;text-align: center">
        工点
      </div>
      <div style="width: 300px;text-align: center">
        开累
      </div>
      <div style="width: 300px;text-align: center">
        初始里程
      </div>
      <div style="width: 300px;text-align: center">
        正\辅洞
      </div>
      <div style="margin-left: 20px">
        操作
      </div>
    </div>
    <div v-for="(item, index) in operArr" :key="index" style="display: flex;justify-content: center;margin-top: 10px">
      <div style="width: 300px;text-align: center;border:1px solid #000">
        {{ item.bdst }}
      </div>
      <div style="width: 350px;text-align: center;border:1px solid #000">
        {{ item.operatingpoint }}
      </div>
      <div style="width: 300px;text-align: center;border:1px solid #000">
        {{ item.addup }}
      </div>
      <div style="width: 300px;text-align: center;border:1px solid #000">
        {{ item.initmile }}
      </div>
      <div v-if="item.tunnel === 0" style="width: 300px;;text-align: center;border:1px solid #000">
        正&emsp;&emsp;洞
      </div>
      <div v-if="item.tunnel === 1" style="width: 300px;text-align: center;border:1px solid #000">
        辅助坑道
      </div>
      <div class="upDownWrapper" style="border:1px solid #000">
        <el-button type="success" @click="upClick(index)" v-if="index !== 0 && index !== operArr.length - 1"
                   style="margin-right: 20px" size="mini">上移
        </el-button>
        <el-button type="success" v-if="index === 0" style="margin-right: 20px" size="mini" disabled>上移</el-button>
        <el-button type="warning" @click="downClick(index)" v-if="index === 0" style="margin-right: 20px" size="mini">
          下移
        </el-button>

        <el-button type="success" @click="upClick(index)" v-if="index === operArr.length - 1" style="margin-right: 20px"
                   size="mini">上移
        </el-button>
        <el-button type="warning" disabled v-if="index === operArr.length - 1" style="margin-right: 20px" size="mini">
          下移
        </el-button>
        <el-button type="warning" @click="downClick(index)" v-if="index !== operArr.length - 1 && index !== 0"
                   style="margin-right: 20px" size="mini">下移
        </el-button>
        <el-button type="primary" @click="editOPer(index)" size="mini" style="padding-right: 20px">修改</el-button>
        <el-popconfirm title="是否确认删除?"
                       @confirm="deloper(index)">
          <template #reference>
            <el-button size="mini" type="danger">删除</el-button>
          </template>
        </el-popconfirm>
      </div>
    </div>

    <!--    修改工点信息-->
    <div>
      <el-dialog
          v-model="dialogVisible"
          title="修改工点信息"
          width="30%"
      >
        <!--        工区-->
        <div>
          <el-tag>工&emsp;&emsp;区</el-tag>
          <el-select style="margin-left: 5px;margin-bottom: 20px" v-model="oper" placeholder="请选择标段" size="small"
                     @change="getoper()">
            <el-option
                v-for="item in bdsts"
                :key="item.bdst"
                :label="item.bdstname"
                :value="item.bdst"
            >
            </el-option>
          </el-select>
        </div>
        <!--        工点-->
        <div style="margin-bottom: 20px">
          <el-tag style="margin-right: 10px">工&emsp;&emsp;点</el-tag>
          <el-input size="small" v-model="opername" placeholder="请输入工点名称" clearable style="width: 350px;"/>
        </div>
        <div style="margin-bottom: 20px">
          <el-tag style="margin-right: 10px">开&emsp;&emsp;累</el-tag>
          <el-input size="small" v-model="addup" placeholder="请输入工点名称" clearable style="width: 350px;"/>
        </div>
        <div style="margin-bottom: 20px">
          <el-tag style="margin-right: 10px">初始里程</el-tag>
          <el-input size="small" v-model="initmile" placeholder="请输入工点名称" clearable style="width: 350px;"/>
        </div>
        <div style="margin-bottom: 20px">
          <el-tag style="margin-right: 10px">正\ 辅洞</el-tag>
          <el-input size="small" v-model="tunnel" placeholder="请输入工点名称" clearable style="width: 350px;"/>
        </div>
        <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="yesEditOper()">确认</el-button>
      </span>
        </template>
      </el-dialog>
    </div>

    <!--    新增工点信息-->
    <div>
      <el-dialog
          v-model="dialogVisible1"
          title="新增工点信息"
          width="30%"
      >
        <!--        工区-->
        <div>
          <el-tag>工&emsp;&emsp;区</el-tag>
          <el-select style="margin-left: 5px;margin-bottom: 20px" v-model="oper" placeholder="请选择标段" size="small"
                     @change="getoper()">
            <el-option
                v-for="item in bdsts"
                :key="item.bdst"
                :label="item.bdstname"
                :value="item.bdst"
            >
            </el-option>
          </el-select>
        </div>
        <!--        工点-->
        <div style="margin-bottom: 20px">
          <el-tag style="margin-right: 10px">工&emsp;&emsp;点</el-tag>
          <el-input size="small" v-model="opername" placeholder="请输入工点名称" clearable style="width: 350px;"/>
        </div>
        <div style="margin-bottom: 20px">
          <el-tag style="margin-right: 10px">开&emsp;&emsp;累</el-tag>
          <el-input size="small" v-model="addup" placeholder="请输入工点名称" clearable style="width: 350px;"/>
        </div>
        <div style="margin-bottom: 20px">
          <el-tag style="margin-right: 10px">初始里程</el-tag>
          <el-input size="small" v-model="initmile" placeholder="请输入工点名称" clearable style="width: 350px;"/>
        </div>
        <div style="margin-bottom: 20px">
          <el-tag style="margin-right: 10px">正\ 辅洞</el-tag>
          <el-input size="small" v-model="tunnel" placeholder="请输入工点名称" clearable style="width: 350px;"/>
        </div>
        <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible1 = false">取消</el-button>
        <el-button type="primary" @click="yesAddOper()">确认</el-button>
      </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import Header from "@/views/header";
import {ElMessage} from 'element-plus';

export default {
  name: "oper",
  data() {
    return {
      operArr: [],
      dialogVisible: false,
      dialogVisible1: false,
      oper: '',
      bdsts: [],
      opername: '',
      addup: '',
      initmile: '',
      tunnel: '',
      bdstid: '',
      operid: ''
    }
  },
  components: {Header},
  created() {
    this.axios.post('/other/getopers', (response) => {
      this.operArr = response.obj;
    })
  },
  methods: {
    // 上移
    upClick(index) {
      let arr = this.operArr[index];
      this.operArr[index] = this.operArr[index - 1];
      this.operArr[index - 1] = arr
    },
    // 下移
    downClick(index) {
      let arr = this.operArr[index];
      this.operArr[index] = this.operArr[index + 1];
      this.operArr[index + 1] = arr
    },
    // 修改工点信息
    editOPer(index) {
      this.dialogVisible = true;
      this.axios.get('/other/exzx', (response) => {
        this.bdsts = response.obj
      });
      this.oper = this.operArr[index].bdst.slice(3);
      this.operid = this.operArr[index].id;
      this.bdstid = this.operArr[index].bdst.slice(0, 2);
      this.opername = this.operArr[index].operatingpoint;
      this.addup = this.operArr[index].addup;
      this.initmile = this.operArr[index].initmile;
      if (this.operArr[index].tunnel === 0) {
        this.tunnel = '正洞'
      }
      if (this.operArr[index].tunnel === 1) {
        this.tunnel = '辅助坑道'
      }
    },
    // 确认修改
    yesEditOper() {
      this.axios.post('/other/editoper', (response) => {
        if (response.obj) {
          ElMessage({
            message: '修改成功！',
            type: 'success',
          });
          this.dialogVisible = false;
          this.axios.post('/other/getopers', (response) => {
            this.operArr = response.obj;
          })
        }
      }, {
        operid: this.operid,
        opername: this.opername,
        bdstid: this.bdstid,
        initmile: this.initmile,
        addup: this.addup,
        tunnel: this.tunnel
      });
    },
    getoper() {
      this.bdstid = this.oper
    },
    // 新增工点
    addOPer() {
      this.dialogVisible1 = true;
      this.axios.get('/other/exzx', (response) => {
        this.bdsts = response.obj
      });
    },
    yesAddOper() {
      this.axios.post('/other/addoper', (response) => {
        if (response.obj) {
          ElMessage({
            message: '添加成功！',
            type: 'success',
          });
          this.dialogVisible1 = false;
          this.axios.post('/other/getopers', (response) => {
            this.operArr = response.obj;
          })
        }
      }, {
        opername: this.opername,
        bdstid: this.bdstid,
        initmile: this.initmile,
        addup: this.addup,
        tunnel: this.tunnel
      });
    },
    deloper(index) {
      this.axios.post('/other/deloper', (response) => {
        if (response.obj) {
          ElMessage({
            message: '添加成功！',
            type: 'success',
          });
          this.axios.post('/other/getopers', (response) => {
            this.operArr = response.obj;
          })
        }
      }, {
        operid: this.operArr[index].id
      })
    },
    changeFalg() {
      this.axios.post('/other/changeflag', (response) => {
        ElMessage({
          message: '顺序更新成功！',
          type: 'success',
        });
        this.axios.post('/other/getopers', (response) => {
          this.operArr = response.obj;
        })
      }, {
        processgs: JSON.stringify(this.operArr)
      })
    }
  }
}
</script>

<style scoped>
#admindiv1 {
  border-radius: 5px;
  background-color: white;
  display: flex;
  width: 95%;
  height: 40px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  justify-content: space-between;
}
</style>